// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { postJson } from 'Utils/http';
import { API_URL } from 'Config';
import deliveryEstimate from 'Utils/deliverables';
import { RootState } from 'Store';
import { getOrders } from 'Features/order/orderSelectors';
import { GetBundlesFailureAction, GetBundlesStartAction, GetBundlesSuccessAction, SelectedAdditionalDeliverablesAndLayersAction, SetDefaultBundleAction, SetDeliverablesAction, SetDeliveryDateAction, SetDeliveryDaysAction } from './bundleActions';

export const GetBundlesThunk = (orderId: string, projectId: string, projectType: string, bundleSubtype: string, isEstimate = false) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const url = projectType === 'utilities' ? `${API_URL}/deliverables/getUtilitiesDeliverables` : `${API_URL}/deliverables/getStandardDeliverables`;
    const { token } = getState().auth;

    dispatch(GetBundlesStartAction());
    const data = { orderId, projectId, subtype: bundleSubtype };
    const result = await postJson<IBundleResponse>(url, data, token);
    let bundleName = projectType === 'standard' ? '2D + Topo' : '';
    let density = 'normal';
    let deliverySpeed = 'normal';
    if (result.success) {
      dispatch(GetBundlesSuccessAction(result.data));
      const { activeOrder, placedOrder } = getOrders(getState());
      const { project } = getState().project;
      if (isEstimate) {
        bundleName = activeOrder.bundleName;
        density = activeOrder.density;
        deliverySpeed = activeOrder.deliverySpeed;
      }
      const bundles = result.data.deliverables.groupings;
      let findBundles;
      if (bundles.length === 1) {
        [findBundles] = bundles;
      } else if (placedOrder && project.qcLevel) {
        findBundles = bundles.find((b) => b.name === '2D Only - Express AI');
      } else if (bundleName?.length) {
        findBundles = bundles.find((b) => b.name === (bundleName) && b.density === (density || 'normal') && b.deliverySpeed === (deliverySpeed || 'normal'));
      } else {
        [findBundles] = bundles;
      }
      const { additionalDeliverables, bundleDeliveryTime, bundleId } = findBundles;
      dispatch(SetDefaultBundleAction(findBundles));

      if (isEstimate) {
        let deliveryTime = bundleDeliveryTime;
        const { addOns } = activeOrder;
        addOns.forEach((addOn) => {
          const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
          deliveryTime += additional.time;
          dispatch(SelectedAdditionalDeliverablesAndLayersAction(additional));
        });
        // If there are selected add-ons in A la carte bundle, add 2 days to the delivery time
        if (addOns.length > 0 && bundleId === 5) {
          if (deliverySpeed === 'expedited' || density === 'low') deliveryTime += 1.5;
          else if (deliverySpeed === 'super_expedited') deliveryTime += 1;
          else deliveryTime += 2;
        }
        dispatch(SetDeliveryDaysAction(deliveryTime));
        dispatch(SetDeliveryDateAction(deliveryEstimate(deliveryTime)));
      } else {
        dispatch(SetDeliveryDaysAction(bundleDeliveryTime));
        dispatch(SetDeliveryDateAction(deliveryEstimate(bundleDeliveryTime)));
      }
    } else {
      dispatch(GetBundlesFailureAction());
    }
    return {
      deliverySpeed,
    };
  };

export const SetDeliverablesThunk = (bundle: IBundle, keepAdditionals: boolean) => (dispatch: Dispatch, getState: () => RootState) => {
  const { selectedAdditionals } = getState().bundle;
  const { additionalDeliverables, bundleDeliveryTime, bundleId, deliverySpeed, density } = bundle;

  dispatch(SetDeliverablesAction(bundle));
  // keepAdditionals boolean is true when density and delivery speed are changed, and false when bundle is changed
  if (selectedAdditionals.length > 0 && keepAdditionals) {
    let deliveryTime = bundleDeliveryTime;
    selectedAdditionals.forEach((addOn) => {
      const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
      deliveryTime += additional.time;
      dispatch(SelectedAdditionalDeliverablesAndLayersAction(additional));
    });
    // If there are selectedAdditionals in A la carte bundle, add 2 days to the delivery time
    if (bundleId === 5) {
      if (deliverySpeed === 'expedited' || density === 'low') deliveryTime += 1.5;
      else if (deliverySpeed === 'super_expedited') deliveryTime += 1;
      else deliveryTime += 2;
    }
    dispatch(SetDeliveryDaysAction(deliveryTime));
    dispatch(SetDeliveryDateAction(deliveryEstimate(deliveryTime)));
  }
};
