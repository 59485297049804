// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from 'reselect';
import type { RootState } from 'Store';

const getOrdersList = (state: RootState) => state.order.list;
const getOrderId = (state: RootState) => state.order.orderId;
const getProjectId = (state: RootState) => state.project.projectId;
const getProjectsList = (state: RootState) => state.projectList.list;

const getOrders = createSelector(
  [getProjectId, getOrdersList],
  (projectId, orders) => {
    const stateOrders = orders.filter((o) => o.ownerProject === projectId);
    const projectOrders = stateOrders.sort((order1, order2) => ((order1.createdAt < order2.createdAt) ? 1 : -1));
    const projectOrdersReverse = stateOrders.slice().sort((order1, order2) => (
      (order1.createdAt > order2.createdAt) ? 1 : -1
    ));
    const ordersWithCadFiles = projectOrders.filter((o) => o.cadFiles && o.cadFiles.length > 0);
    const nonActiveOrders = projectOrders.filter((o) => o.orderStatus > 0);
    const activeOrder = projectOrders.find((o) => o.orderStatus === 0);
    const placedOrder = projectOrders.find((o) => o.orderStatus >= 1);
    const result = {
      projectOrders,
      projectOrdersReverse,
      ordersWithCadFiles,
      nonActiveOrders,
      activeOrder,
      placedOrder,
    };

    return result;
  },
);

const selectCurrentOrder = createSelector(
  [getOrders, (_, orderId) => orderId],
  (orders, orderId) => {
    const currentOrder = orders.ordersWithCadFiles.find((order) => order._id === orderId);
    return currentOrder;
  },
);

// Selector to evaluate the cases for viewing the CAD view tab
const cadViewPermissions = createSelector(
  [getProjectId, getOrdersList],
  (projectId, orders) => {
    const projectOrders = orders.filter((o) => o.ownerProject === projectId);

    const orderWithExpressAIFile = (projectOrders.some((order) => order.cadFiles && order.cadFiles.some((file) => (file?.expressAIUpload && file?.expressAIEmailSent))));

    const fullyCompletedOrders = projectOrders.some((order) => order.orderStatus === 7);
    const autonomousOrFullyCompletedOrders = projectOrders.some((order) => order.orderStatus === 7 || order.orderStatus === 2);

    const cadViewProd = orderWithExpressAIFile || fullyCompletedOrders;
    const cadViewDevStage = orderWithExpressAIFile || autonomousOrFullyCompletedOrders;
    const result = {
      cadViewProd,
      cadViewDevStage,
    };

    return result;
  },
);

const getOrderIds = createSelector(
  [getProjectId, getOrdersList],
  (projectId, orders) => {
    const projectOrders = orders.filter((o) => o.ownerProject === projectId);

    return projectOrders.map((o) => o._id);
  },
);

// Selector to get parent project for an order
const getOrderOwnerProject = createSelector(
  [getOrderId, getOrdersList],
  (orderId, orders) => {
    const order = orders.find((o) => o._id === orderId);
    return order.ownerProject;
  },
);

const getProcessingOrders = createSelector(
  [getOrdersList],
  (orders) => {
    if (!orders) return [];
    // orders with orderStatus from 1 through 6 will show the pizza tracker on the project card
    return orders.filter((o) => [1, 2, 3, 4, 5, 6].includes(o.orderStatus));
  },
);

/**
 * Selector function to get orders for the orderHistory table
 */
const getCompletedOrders = createSelector(
  [getOrdersList, getProjectsList],
  (orders, projects) => {
    if (!orders) return [];
    if (!projects) return [];
    const results: any[] = [];
    // Order history tab should display completed orders with status 7 and order acreage > 0
    orders.filter((order) => (order.orderStatus === 7 && order.acreage > 0) || order.orderStatus === 8).forEach((order) => {
      const project = projects.find((p) => p._id === order.ownerProject);
      if (project) {
        const projectName = projects.find((p) => p._id === order.ownerProject).name;
        const imageryProvider = project.imageryRequest?.provider;
        const result = { ...order, name: projectName, imageryProvider };
        results.push(result);
      }
    });

    // Some completed orders don't have orderDate field. So display orders which have orderDate first and then the orders without orderDate
    // Orders without orderDate are sorted using their createdAt date
    const ordersWithOrderDate = results.filter((order) => order.orderDate).sort((order1, order2) => ((order1.orderDate < order2.orderDate) ? 1 : -1));
    const ordersWithoutOrderDate = results.filter((order) => !order.orderDate).sort((order1, order2) => ((order1.createdAt < order2.createdAt) ? 1 : -1));

    return [...ordersWithOrderDate, ...ordersWithoutOrderDate];
  },
);

export {
  getOrders,
  selectCurrentOrder,
  getOrderIds,
  getOrderOwnerProject,
  getProcessingOrders,
  getCompletedOrders,
  cadViewPermissions,
};
