// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  newLayerDialog: {
    '& .MuiDialog-paperWidthSm': {
      width: '600px',
      padding: '16px',
    },
  },
  newLayerDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  newLayerDialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '22px',
  },
  dialogText: {
    fontWeight: 400,
  },
  dialogButton: {
    color: theme.palette.primary.main,
  },
  selectWeight: {
    padding: '0px 21px 0px 0px',
    display: 'flex',
    border: '1px solid grey',
    borderRadius: '4px',
    '& .MuiSelect-select': {
      display: 'flex',
    },
  },
  horizontalLine: {
    backgroundColor: '#000000',
    width: '30%',
    marginRight: '10px',
    marginLeft: '0px',
  },
  menuItem: {
    display: 'flex',
  },
  formGroup: {
    flex: 1,
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
  },
  infoIcon: {
    transform: 'rotate(180deg)',
  },
  layerNameInput: {
    color: 'black',
    fontWeight: '400',
  },
  lineWeightInput: {
    width: '100%',
    height: '50px',
    paddingLeft: '15px',
  },
  lineColorInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    border: '1px solid #000000',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  lineColorSample: {
    width: '80px',
    height: '100%',
  },
  closeButton: {
    color: 'grey',
  },
  lineColorText: {
    marginRight: '35px',
  },
});

export default useStyles;
