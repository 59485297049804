// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSlice } from '@reduxjs/toolkit';
import GenerateGuid from 'Utils/guid';
import { defaultBufferSize } from 'Utils/constants';
import {
  SetKmlAction,
  SetKmlErrorAction,
  SetLineStringUploadAction,
  LoadKmlsStartAction,
  LoadKmlsSuccessAction,
  LoadKmlsFailureAction,
  ResetBufferStateAction,
  CreateKmlBufferStartAction,
  CreateKmlBufferSuccessAction,
  CreateKmlBufferFailureAction,
  SetShowBufferInfoAction,
  SetBufferSizeAction,
  AdminToggleKmlVisibilityAction,
  SetKmlBBoxAction,
  SetShowBusinessDaysAction,
  ResetKmlAction,
  SetRoadNetworkClickedAction,
  CreateRoadNetworkKmlBufferStartAction,
  CreateRoadNetworkKmlBufferSuccessAction,
  CreateRoadNetworkKmlBufferFailureAction,
  SetSelectedKmlIdAction,
  OverwriteKmlsAction,
  SetMultipleGeometriesUploadAction,
} from './kmlActions';

const initialState: IKmlState = {
  kmls: {},
  loading: false,
  showBusinessDays: true,
  multipleGeometriesUpload: false,
  lineStringKmlUpload: false,
  bufferSize: defaultBufferSize,
  getRoadNetwork: false,
  creatingRoadNetworkBuffer: false,
  creatingBuffer: false,
  showBufferInfo: false,
  kmlError: false,
  bbox: null,
  selectedKmlId: null,
};

const kmlSlice = createSlice({
  name: 'kml',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SetKmlAction, (state, action) => {
        state.kmls[action.payload.orderId] = {
          id: GenerateGuid(),
          visible: true,
          featureCollection: action.payload.kml,
        };
      })
      .addCase(OverwriteKmlsAction, (state, action) => {
        state.kmls = action.payload;
      })
      .addCase(ResetKmlAction, (state, action) => {
        state.kmls = {};
      })
      .addCase(SetKmlErrorAction, (state, action) => {
        state.kmlError = action.payload;
      })
      .addCase(SetKmlBBoxAction, (state, action) => {
        state.bbox = action.payload;
      })
      .addCase(SetShowBusinessDaysAction, (state, action) => {
        state.showBusinessDays = action.payload;
      })
      .addCase(ResetBufferStateAction, (state) => {
        state.bufferSize = defaultBufferSize;
        state.showBufferInfo = false;
        state.kmlError = false;
      })
      .addCase(SetLineStringUploadAction, (state, action) => {
        state.lineStringKmlUpload = action.payload;
      })
      .addCase(SetRoadNetworkClickedAction, (state, action) => {
        state.getRoadNetwork = action.payload;
      })
      .addCase(LoadKmlsStartAction, (state) => {
        state.loading = true;
      })
      .addCase(LoadKmlsSuccessAction, (state, action) => {
        const kmls: {
          [key: string]: {
            id: string;
            visible: boolean;
            featureCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
          };
        } = {};

        action.payload.forEach((p) => {
          kmls[p.orderId] = {
            id: GenerateGuid(),
            visible: !_ADMIN_,
            featureCollection: p.kml,
          };
        });

        state.kmls = kmls;
        state.loading = false;
      })
      .addCase(LoadKmlsFailureAction, (state) => {
        state.loading = false;
      })
      .addCase(CreateKmlBufferStartAction, (state) => {
        state.creatingBuffer = true;
      })
      .addCase(CreateKmlBufferSuccessAction, (state) => {
        state.creatingBuffer = false;
      })
      .addCase(CreateKmlBufferFailureAction, (state) => {
        state.creatingBuffer = false;
      })
      .addCase(CreateRoadNetworkKmlBufferStartAction, (state) => {
        state.creatingRoadNetworkBuffer = true;
      })
      .addCase(CreateRoadNetworkKmlBufferSuccessAction, (state) => {
        state.creatingRoadNetworkBuffer = false;
      })
      .addCase(CreateRoadNetworkKmlBufferFailureAction, (state) => {
        state.creatingRoadNetworkBuffer = false;
      })
      .addCase(SetShowBufferInfoAction, (state, action) => {
        state.showBufferInfo = action.payload;
      })
      .addCase(SetBufferSizeAction, (state, action) => {
        state.bufferSize = action.payload;
      })
      .addCase(AdminToggleKmlVisibilityAction, (state, action) => {
        Object.keys(state.kmls).forEach((id) => {
          if (action.payload === id) {
            state.kmls[id].visible = true;
          } else {
            state.kmls[id].visible = false;
          }
        });
      })
      .addCase(SetSelectedKmlIdAction, (state, action) => {
        state.selectedKmlId = action.payload;
      })
      .addCase(SetMultipleGeometriesUploadAction, (state, action) => {
        state.multipleGeometriesUpload = action.payload;
      });
  },
});

export const { reducer } = kmlSlice;

export default reducer;
