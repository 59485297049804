// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from '@reduxjs/toolkit';

const selectProjectList = (state) => state.projectList.list;

const selectProjectId = (state) => state.project.projectId;

const selectProjectInfo = createSelector([selectProjectList, (state) => state.auth.user, (_, project) => project], (list, user, project) => {
  const dashboardProjectFilter = list.filter((o) => o._id === project._id);
  const [dashboardProject] = dashboardProjectFilter;
  if (!dashboardProject) {
    return {
      placed: 0,
      autonomous: 0,
      cleaning: 0,
      complete: 0,
    };
  }
  const orders = [...dashboardProject.orders];

  const dashboardProjectOrders = orders.sort((order1, order2) => (order1.createdAt < order2.createdAt ? 1 : -1));
  const orderFinalized = dashboardProjectOrders.some((o) => o.orderStatus === 7);

  const orderStatusResult = orders.reduce((result, order) => {
    if ([1, 2, 3, 4, 5, 6, 7, 8].includes(order.orderStatus)) {
      result[order.ownerProject] = order.orderStatus;
    }
    return result;
  }, {} as { [key: string]: number });

  const orderEstimateResult = orders.filter((order) => order.isEstimate).map((order) => order.ownerProject);

  const expressAIReady = orders?.some((order) => order.cadFiles?.some((cadfile) => (cadfile.expressAIUpload && cadfile.expressAIEmailSent)));

  // Check if any of the orders in the project are being processed
  const projectProcessing = dashboardProjectOrders.some((order) => order.orderStatus > 0);
  // Check if project is created by the current user
  const isProjectCreatedByUser = project.owner === user._id;
  // Variable to check if a project can be deleted
  const cannotDelete = projectProcessing || !isProjectCreatedByUser;
  const orderStatus = orderStatusResult[project._id];
  const orderEstimate = orderEstimateResult.includes(project._id);
  const { imageryRequest, rasterRequest } = project;
  return {
    isInProgress: orderStatus && (orderStatus > 0 && orderStatus < 7),
    placed: orderStatus && orderStatus === 1 ? 0 : 0,
    autonomous: orderStatus && (orderStatus === 1 || orderStatus === 2 || orderStatus === 3) ? 1 : 0,
    cleaning: orderStatus && (orderStatus === 4 || orderStatus === 5 || orderStatus === 6) ? 2 : 0,
    complete: orderStatus && orderStatus === 7 ? 3 : 0,
    cannotDelete,
    orderFinalized,
    expressAIReady,
    orderEstimate,
    orderStatus,
    imageryRequest,
    rasterRequest,
  };
});

const selectAllCoordinates = createSelector(
  [selectProjectId, selectProjectList],
  (projectId, projects) => {
    const coordinatesInfo = projects
      .filter((project) => project.middlePoint)
      .map((p) => ({
        coords: [p.middlePoint.longitude, p.middlePoint.latitude],
        hovered: p._id === projectId,
      }));
    return coordinatesInfo;
  },
);

const selectHoveredCoordinates = createSelector(
  [selectProjectId, selectProjectList],
  (projectId, projects) => {
    if (projectId !== null) {
      const project = projects.filter((p) => p._id === projectId);
      if (project[0] && project[0].middlePoint) {
        const projectCoordinates = [project[0].middlePoint.longitude, project[0].middlePoint.latitude];
        return projectCoordinates;
      }
    }
    return null;
  },
);

export { selectProjectInfo, selectAllCoordinates, selectHoveredCoordinates };
