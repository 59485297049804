// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Divider, Typography, Select, MenuItem, InputBase, Button, Tooltip, Badge, IconButton, Fade, CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import moment from 'moment-timezone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from 'Components/ConfirmDialog';
import UploadFilesDialog from 'Components/UploadFilesDialog';
import { Box, useTheme } from '@mui/system';
import LightTooltip from 'Components/Tooltip';
import downloadIcon from 'Assets/download-icon.png';
import { GetAdminProjectsRoute } from 'Utils/routes';
import ImageryCaptureDialog from '../ImageryCaptureDialog';
import { useNavigate } from 'react-router-dom';
import stylesDef from './styles';
import ProjectLayersList from 'Features/files/Sidebar/CadDrawings/ProjectLayersList';
import Sidebar from 'Components/Sidebar';
import { useAppDispatch, useAppSelector } from 'Hooks';
import { DeleteLasFileThunk, DeleteTifFileThunk, DownloadTifThunk, SendImageryUploadEmailThunk, SendRasterUploadEmailThunk } from 'Features/project/projectThunk';
import { DeleteProjectAdminThunk } from 'Features/admin/adminThunk';
import { ClearDownloadFilesThunk, DownloadPostgisDxfThunk, SetDownloadFilesState, SetOutputLas } from 'Features/order/orderThunk';
import { SetImageryCaptureDateAction } from 'Features/fileUpload/fileUploadActions';

moment.tz.setDefault('America/New_York');

export const ProjectDetails = ({list, selectIndex, siteId, imageryRequest, rasterRequest, isEstimate, layers, currentIndex, projectFileStatuses}) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { emailLoading } = useAppSelector((state) => state.project);
  const rasterTif = useAppSelector((state) => state.project.project.tifFiles?.some((obj) => obj.autonomous));
  const tifFiles = useAppSelector((state) => state.project.project.tifFiles || null);
  const downloadFileStatuses = useAppSelector((state) => state.order?.dxfFileStatus);
  const downloading = useAppSelector((state) => state.order?.dxfFileStatus?.some((obj) => (obj.fileReadyStatus.dxfFile === 0)));
  const downloaded =  useAppSelector((state) =>state.order?.dxfFileStatus?.some((obj) => (obj.fileReadyStatus.dxfFile === 1)));

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState('');
  const [deleteFileType, setDeleteFileType] = useState('');
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const [download, setDownload] = useState(false);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [imageryCaptureDialogVisible, setImageryCaptureDialogVisible] = useState(false);

  const index = downloadFileStatuses.findIndex((obj: IDxfFileState) => obj.siteId === siteId);
  const downloadFileStatusesIndex = index >= 0 && downloadFileStatuses[index];

  let requested; let uploaded; let rejected; let notificationSent;

  // For later
  // let provider; let captured; let projection; let filetypes;
  if (imageryRequest) {
    ({ requested, uploaded, rejected, notificationSent } = imageryRequest);
  }
  if (rasterRequest && !imageryRequest) {
    ({ requested, rejected, notificationSent } = rasterRequest);
    uploaded = rasterTif;
  }

  const imageryRequested = requested && !rejected && !uploaded && !notificationSent;
  const imageryRejected = requested && rejected;
  const imageryUploaded = requested && !rejected && uploaded && !notificationSent;
  const imageryNotificationSent = requested && !rejected && uploaded && notificationSent;

  const rasterRequested = requested && !rejected && !uploaded && !notificationSent;
  const rasterRejected = requested && rejected;
  const rasterUploaded = requested && !rejected && uploaded && !notificationSent;
  const rasterNotificationSent = requested && !rejected && uploaded && notificationSent;

  let imageryRequestTopBarClass;
  let imageryRequestIcon;
  let imageryRequestTitle;
  let imageryRequestDescription;
  let imageryRequestInfo;

  if (imageryRequested) {
    imageryRequestTopBarClass = styles.topBarRequested;
    imageryRequestIcon = <InfoIcon fontSize="small" sx={styles.requested} />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = 'Imagery has been requested for this project. Upload your files above';
    imageryRequestInfo = (
      <Box>
        <b>Requested On:</b>
        {' '}
        {moment(requested).format('LL')}
      </Box>
    );
  } else if (imageryRejected) {
    imageryRequestTopBarClass = styles.topBarRejected;
    imageryRequestIcon = <CheckCircleIcon sx={styles.rejected} fontSize="small" />;
    imageryRequestTitle = 'Imagery Request Rejected';
    imageryRequestDescription = 'User has rejected the imagery requested on this project';
    imageryRequestInfo = (
      <Box>
        <b>Rejected On:</b>
        {' '}
        {moment(rejected).format('LL')}
      </Box>
    );
  } else if (imageryUploaded) {
    imageryRequestTopBarClass = styles.topBarUploaded;
    imageryRequestIcon = <CheckCircleIcon sx={styles.uploaded} fontSize="small" />;
    imageryRequestTitle = 'Imagery Uploaded';
    imageryRequestDescription = 'Requested imagery has been uploaded on this project';
    imageryRequestInfo = (
      <Button sx={styles.requested} onClick={() => dispatch(SendImageryUploadEmailThunk())}>
        <Fade in={emailLoading}>
          <CircularProgress size={24} sx={styles.emailButtonProgress} />
        </Fade>
        <Fade in={!emailLoading}>
          <span>Send Notification</span>
        </Fade>
      </Button>
    );
  } else if (imageryNotificationSent) {
    imageryRequestTopBarClass = styles.topBarUploaded;
    imageryRequestIcon = <CheckCircleIcon sx={styles.uploaded} fontSize="small" />;
    imageryRequestTitle = 'Imagery Uploaded';
    imageryRequestDescription = 'Requested imagery has been uploaded on this project';
    imageryRequestInfo = (
      <Box>
        <b>User Notified On:</b>
        {' '}
        {moment(notificationSent).format('LL')}
      </Box>
    );
  }

  let rasterRequestTopBarClass;
  let rasterRequestIcon;
  let rasterRequestTitle;
  let rasterRequestDescription;
  let rasterRequestInfo;

  if (rasterRequested) {
    rasterRequestTopBarClass = styles.topBarRequested;
    rasterRequestIcon = <InfoIcon fontSize="small" sx={styles.requested} />;
    rasterRequestTitle = 'Rasterization Requested';
    rasterRequestDescription = 'An raster has been requested for this project. Upload your files above';
    rasterRequestInfo = (
      <Box>
        <b>Requested On:</b>
        {' '}
        {moment(requested).format('LL')}
      </Box>
    );
  } else if (rasterRejected) {
    rasterRequestTopBarClass = styles.topBarRejected;
    rasterRequestIcon = <CheckCircleIcon sx={styles.rejected} fontSize="small" />;
    rasterRequestTitle = 'Rasterization Request Rejected';
    rasterRequestDescription = 'User has rejected the raster requested on this project';
    rasterRequestInfo = (
      <Box>
        <b>Rejected On:</b>
        {' '}
        {moment(rejected).format('LL')}
      </Box>
    );
  } else if (rasterUploaded) {
    rasterRequestTopBarClass = styles.topBarUploaded;
    rasterRequestIcon = <CheckCircleIcon sx={styles.uploaded} fontSize="small" />;
    rasterRequestTitle = 'Rasterization Uploaded';
    rasterRequestDescription = 'Requested raster has been uploaded on this project';
    rasterRequestInfo = (
      <Button sx={styles.requested} onClick={() => dispatch(SendRasterUploadEmailThunk())}>
        <Fade in={emailLoading}>
          <CircularProgress size={24} sx={styles.emailButtonProgress} />
        </Fade>
        <Fade in={!emailLoading}>
          <span>Send Notification</span>
        </Fade>
      </Button>
    );
  } else if (rasterNotificationSent) {
    rasterRequestTopBarClass = styles.topBarUploaded;
    rasterRequestIcon = <CheckCircleIcon sx={styles.uploaded} fontSize="small" />;
    rasterRequestTitle = 'Rasterization Uploaded';
    rasterRequestDescription = 'Requested rasterizatiom has been uploaded on this project';
    rasterRequestInfo = (
      <Box>
        <b>User Notified On:</b>
        {' '}
        {moment(notificationSent).format('LL')}
      </Box>
    );
  }

  const DownloadButton = ({ filename, text }: { filename: string, text: string }) => (
    <IconButton
      aria-label={text}
      onClick={() => dispatch(DownloadTifThunk(filename))}
    >
      <img src={downloadIcon} alt={text} />
    </IconButton>
  );

  const userReUpFiles = (list.length === 1 && list[0].orderStatus === 0) || list.some((order) => order.orderStatus === 3);
  const disableFileUpload = !!(requested) && (list.length > 1 && list.some((order) => order.orderStatus > 0));
  const placedOrders = list.some((order) => order.orderStatus > 0);

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    dispatch(DeleteProjectAdminThunk(list[currentIndex].ownerProject));
    navigate(GetAdminProjectsRoute(list[currentIndex].ownerOrganization));
    e.stopPropagation();
  };

  const deleteInputFile = (id: string, type: string) => {
    setShowConfirmDialog(true);
    setDeleteFileId(id);
    setDeleteFileType(type);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    if (deleteFileType === 'tif') {
      dispatch(DeleteTifFileThunk(deleteFileId));
    } else {
      dispatch(DeleteLasFileThunk(deleteFileId));
    }
    e.stopPropagation();
  };

  const uploadInputFilesClick = () => {
    dispatch(SetOutputLas(false)); // ensures that any LAS uploads will be treated as input files.
    if (imageryRequest) {
      dispatch(SetImageryCaptureDateAction(null));
      setImageryCaptureDialogVisible(true);
    } else {
      setUploadFilesFormVisible(true);
    }
  };

  const PrepareOutputFiles = async () => {
    // check what are the files requested for dxf and las
    if (layers) {
      const downloadState = {
        dxfFile: true,
        lasFile: false,
        surfaceFile: false,
        shapeFile: false,
        demFile: false,
        dtmFile: false,
        dwgFile: false,
        dgnFile: false,
        geodbFile: false,
        geoJson: false,
      };
      setDownload(true);
      await dispatch(SetDownloadFilesState(siteId, downloadState));
      await dispatch(DownloadPostgisDxfThunk(list[currentIndex]._id, siteId, false, downloadState));
    }
  };
  const DownloadOutputFiles = async () => {
    const filesReadyToDownload = [];
    if (index >= 0 && downloadFileStatusesIndex.url) {
      filesReadyToDownload.push(downloadFileStatusesIndex.url);
    }
    const interval = 3000;
    filesReadyToDownload.forEach((file, i) => {
      setTimeout(async () => {
        window.open(file, '_self');
      }, i * interval);
    });
    await dispatch(ClearDownloadFilesThunk(siteId));
    setDownload(false);
  };
  const ButtonFunction = () => {
    // eslint-disable-next-line no-unused-expressions
    downloaded ? DownloadOutputFiles() : PrepareOutputFiles();
  };

  const onCancel = () => setShowConfirmDialog(false);
  const autonomousFileIds = tifFiles?.filter((file) => file.autonomous === true).map((file) => file._id);

  return (
    <Sidebar>
      <Box sx={styles.sidebarContent}>
        <Select
          variant="outlined"
          input={<InputBase style={{ width: '400px' }} />}
          sx={styles.dropdownText}
          value={currentIndex}
          renderValue={(currentIndex: number) => <>{`Order ${currentIndex + 1}`}</>}
          styles={{ root: styles.select }}
          disabled={list.filter((order) => order.boundaryFile).length === 0}
        >
          {
            list.map((order, i) =>
              (<MenuItem key={order._id} value={order._id} onClick={() => selectIndex(i)}>{`Order ${i + 1}`}</MenuItem>
              ))
          }
        </Select>
        <Divider sx={styles.divider} />
        <>
          <Box sx={styles.inputData}>
            <Typography variant="h4">Input data</Typography>
            <Button
              disabled={disableFileUpload || (!isEstimate && !!imageryRequest)}
              onClick={uploadInputFilesClick}
              color="primary"
            >
              Upload files
            </Button>
          </Box>
          {imageryRequest && (
            <Box sx={{ ...styles.inputControls, ...imageryRequestTopBarClass }}>
              <Typography sx={styles.buttons} variant="h4">
                {imageryRequestIcon}
                <Box sx={styles.imageryTitle}>
                  {imageryRequestTitle}
                </Box>
              </Typography>
              <Typography sx={styles.divider} variant="body1">{imageryRequestDescription}</Typography>
              {imageryRequestInfo}
            </Box>
          )}
          {rasterRequest && (
            <Box sx={{...styles.inputControls, ...rasterRequestTopBarClass}}>
              <Typography sx={styles.buttons} variant="h4">
                {rasterRequestIcon}
                <Box sx={styles.imageryTitle}>
                  {rasterRequestTitle}
                </Box>
              </Typography>
              <Typography sx={styles.divider} variant="body1">{rasterRequestDescription}</Typography>
              {rasterRequestInfo}
            </Box>
          )}
          {projectFileStatuses?.map((file) => (
            <Box sx={styles.files} key={file.id}>
              <Box sx={styles.fileInfo}>
                {(file.adminUpload || autonomousFileIds.includes(file.id)) && (
                  <LightTooltip title="Uploaded by Admin" placement="top">
                    <SettingsOutlinedIcon />
                  </LightTooltip>
                )}
                <Typography>{`${file.name} - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
              </Box>
              {(file.status === 'ERROR') && (
                <Tooltip title={file.messages.join(', ')} placement="top">
                  <ErrorIcon />
                </Tooltip>
              )}
              <Box sx={styles.buttons}>
                <Box>
                  <DownloadButton
                    filename={file.name}
                    text={`Download .${file.type}`}
                  />
                </Box>
                <Box>
                  {(userReUpFiles || (file.status === 'ERROR') || (file.adminUpload)) && (
                    <IconButton
                      onClick={() => deleteInputFile(file.id, file.type)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </>
        {(layers) && (
          <>
            <Divider sx={styles.divider} />
            <Box sx={styles.projectListContainer}>
              <Box>
                <ProjectLayersList order={list[currentIndex]} layers={layers} siteId={siteId} expressAIInfo={false} />
              </Box>
              <Box sx={styles.downloadButtonContainer}>
                { (downloaded && download) ? (
                  <Badge color="primary" overlap="circle" variant="dot">
                    <IconButton sx={styles.iconButton} onClick={ButtonFunction}>
                      <img src={downloadIcon} alt="Download .dxf" />
                    </IconButton>
                  </Badge>
                ) : (
                  <Button
                    disabled={!layers}
                    onClick={ButtonFunction}
                    sx={styles.downloadButton}
                    color="primary"
                  >
                    {
                      (download && downloading && !downloaded) &&
                  (
                    <CircularProgress size={16} sx={styles.buttonProgress} color="primary" />
                  )
                    }
                    {(!download) &&
                (
                  <IconButton
                    aria-label="Download"
                    title="Download"
                    color="primary"
                    sx={styles.downloadButton}
                  >
                    <img src={downloadIcon} alt="Download" />
                  </IconButton>
                )}
                  </Button>
                )}
              </Box>
            </Box>

          </>
        )}
        {!placedOrders && (
          <Box sx={styles.deleteButtonContainer}>
            <Box />
            <Box sx={styles.deleteButton}>
              <DeleteIcon />
              <Button sx={styles.deleteButtonText} onClick={() => setShowConfirmDeleteProject(true)}>Delete Project</Button>
            </Box>
          </Box>
        )}
        <ConfirmDialog
          showDialog={showConfirmDeleteProject}
          onOk={onOkDeleteProject}
          onCancel={() => setShowConfirmDeleteProject(false)}
          contentText="Selected project will be deleted"
        />
        <ConfirmDialog
          showDialog={showConfirmDialog}
          onOk={onOk}
          onCancel={onCancel}
          contentText={`Selected .${deleteFileType} file will be removed `}
        />
        <ImageryCaptureDialog showDialog={imageryCaptureDialogVisible} provider={imageryRequest?.provider} onCancel={() => setImageryCaptureDialogVisible(false)} onNext={() => setUploadFilesFormVisible(true)} />
        <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
      </Box>
    </Sidebar>
  );
};

export default ProjectDetails;
