// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, useTheme } from '@mui/system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingSpinner from 'Components/LoadingSpinner';
import { GetProjectDetailsRoute } from 'Utils/routes';
import { useAppSelector, useAppDispatch } from 'Hooks';
import { SetProjectIdAction } from 'Features/project/projectActions';
import { GetProjectThunk } from 'Features/project/projectThunk';
import { GetOrdersThunk } from 'Features/order/orderThunk';
import { Init3DViewerThunk } from 'Features/viewer3D/viewer3DThunk';
import { Clear3DLineworkAction, SetClassVisibleAction, SetOrder3DCadFilesListAction, SetToggleClassAction } from 'Features/viewer3D/viewer3DActions';
import { classification2Classes, classification6Classes, classification6ClassesNew } from 'Utils/constants';
import Sidebar from './Sidebar';
import Map3D from './Map3D';
import stylesDef from './styles';

const Viewer3D = () => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const { viewer3D } = useFlags();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { order3DCadFilesList } = useAppSelector(
    (state) => state.viewer3D,
  );
  const lasFiles = useAppSelector(
    (state) => state.project.project?.lasFiles,
  );

  const [loading, setLoading] = useState(true);
  const [showPointCloud, setShowPointCloud] = useState(true);
  const [showClassifiedPointCloud, setShowClassifiedPointCloud] = useState({});
  const [showTerrain, setShowTerrain] = useState(false);
  const [resetZoom, setResetZoom] = useState(false);
  const [lasFilesDisplayStatuses, setLasFilesDisplayStatuses] = useState<{[key: string]: boolean}>({});
  const [lasFilesWith3DTile, setLasFilesWith3DTile] = useState([]);

  useEffect(() => {
    if (lasFiles?.length && order3DCadFilesList && !lasFilesWith3DTile.length) {
      const lasWith3d: ILasFile[] = lasFiles?.filter((lasFile) => !lasFile.ownerOrder && lasFile.tile3DUrl)?.map((lasFile) => ({
        name: lasFile.filename.split('/').pop(),
        lasFilename: lasFile.filename.split('/').pop().replace(/\.[^/.]+$/, ''),
        ...lasFile,
      })) || [];
      const completeOrders = Object.keys(order3DCadFilesList);
      const classifiedLas: {[key: string]: boolean} = {};
      const classVisible: any = {};
      completeOrders.forEach((orderId) => {
        const latestOutputLasWith3d = lasFiles?.filter((lasFile) => lasFile.ownerOrder === orderId)
          ?.sort((las1, las2) => {
            if (las1.createdAt < las2.createdAt) return -1;
            return 1;
          })?.pop();
        if (latestOutputLasWith3d) {
          setShowPointCloud(false);
          classifiedLas[orderId] = true;
          classVisible[orderId] = {};
          if (latestOutputLasWith3d?.newClasses) {
            classification6ClassesNew.forEach((item: any) => {
              classVisible[orderId][item.name] = true;
            });
          } else if (latestOutputLasWith3d?.classes === 2) {
            classification2Classes.forEach((item: any) => {
              classVisible[orderId][item.name] = true;
            });
          } else if (latestOutputLasWith3d?.classes === 6) {
            classification6Classes.forEach((item: any) => {
              classVisible[orderId][item.name] = true;
            });
          }
          lasWith3d.push({
            ...latestOutputLasWith3d,
            lasFilename: latestOutputLasWith3d.filename.split('/').pop().replace(/\.[^/.]+$/, ''),
          });
        }
      });

      dispatch(SetClassVisibleAction(classVisible));
      setShowClassifiedPointCloud(classifiedLas);
      setLasFilesWith3DTile(lasWith3d);
    }
  }, [lasFiles, order3DCadFilesList]);

  useEffect(() => {
    if (!viewer3D) {
      navigate(GetProjectDetailsRoute(params.id));
    } else {
      const onLoad = async () => {
        const projectId = params.id;
        if (projectId) {
          dispatch(SetProjectIdAction(projectId));
          await dispatch(GetProjectThunk());
          await dispatch(GetOrdersThunk(projectId));
          await dispatch(Init3DViewerThunk());
          setLoading(false);
        }
      };
      onLoad();
    }
    return () => {
      dispatch(SetProjectIdAction(null));
      dispatch(Clear3DLineworkAction());
      dispatch(SetOrder3DCadFilesListAction(null));
      dispatch(SetClassVisibleAction({}));
      dispatch(SetToggleClassAction(null));
    };
  }, []);

  useEffect(() => {
    if (!loading && !lasFilesWith3DTile.length) {
      navigate(GetProjectDetailsRoute(params.id));
    } else if (!loading && lasFilesWith3DTile.length) {
      const status: {[key: string]: boolean} = {};
      lasFilesWith3DTile.forEach((las: any) => {
        const displayName = las.lasFilename;
        status[displayName] = true;
      });
      setLasFilesDisplayStatuses(status);
    }
  }, [loading, JSON.stringify(lasFiles)]);

  const toggleLasVisibility = (fileName: string, visible: boolean) => {
    const lasFilesDisplayStatusesCopy = { ...lasFilesDisplayStatuses };
    lasFilesDisplayStatusesCopy[fileName] = visible;
    setLasFilesDisplayStatuses(lasFilesDisplayStatusesCopy);
  };

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar
        lasFilesWith3DTile={lasFilesWith3DTile}
        showPointCloud={showPointCloud}
        setShowPointCloud={setShowPointCloud}
        showClassifiedPointCloud={showClassifiedPointCloud}
        setShowClassifiedPointCloud={setShowClassifiedPointCloud}
        showTerrain={showTerrain}
        setShowTerrain={setShowTerrain}
        setResetZoom={setResetZoom}
        toggleLasVisibility={toggleLasVisibility}
        lasFilesDisplayStatuses={lasFilesDisplayStatuses}
      />
      <Box sx={styles.map}>
        <Map3D
          lasFilesWith3DTile={lasFilesWith3DTile}
          showPointCloud={showPointCloud}
          showClassifiedPointCloud={showClassifiedPointCloud}
          showTerrain={showTerrain}
          resetZoom={resetZoom}
          setResetZoom={setResetZoom}
          lasFilesDisplayStatuses={lasFilesDisplayStatuses}
        />
      </Box>
    </LoadingSpinner>
  );
};

export default Viewer3D;
