// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// This function returns the status text corresponding to an orderStatus
/* eslint-disable import/prefer-default-export */
export const GetOrderStatus = (status: number) =>
  (status === 0 && 'Created') ||
  (status === 1 && 'Processing') ||
  (status === 2 && 'Autonomous successful') ||
  (status === 3 && 'Autonomous failed') ||
  (status === 4 && 'Manual drafting') ||
  (status === 5 && 'US QC') ||
  (status === 6 && 'Ready to Upload') ||
  (status === 7 && 'Done') ||
  (status === 8 && 'Canceled');
