// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  layerColor: {
    height: '20px',
    width: '40px',
    border: '1px solid #000000',
  },
  layerColorInput: {
    height: '20px',
    width: '60px',
    border: '1px solid #000000',
    marginRight: '11px',
  },
  typography: {
    paddingLeft: theme.spacing(1),
  },
  select: {
    padding: '0px 21px 0px 0px',
  },
  lineStylePNG: {
    height: '1px',
    marginTop: theme.spacing(1),
  },
  selectWeight: {
    padding: '0px 21px 0px 0px',
    display: 'flex',
    border: '1px solid grey',
    borderRadius: '4px',
    '& .MuiSelect-select': {
      display: 'flex',
    },
  },
  horizontalLine: {
    backgroundColor: '#000000',
    width: '30%',
    marginRight: '10px',
    marginLeft: '0px',
  },
  popover: {
    '& .MuiPaper-root': {
      height: '200px',
      width: '280px',
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '10px',
    },
  },
  menuItem: {
    display: 'flex',
  },
  formGroup: {
    flex: 1,
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  lineWeightInput: {
    width: '100%',
    height: '50px',
    paddingLeft: '15px',
  },
  lineColorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    border: '1px solid #000000',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  lineColorSample: {
    width: '80px',
    height: '100%',
  },
  colorText: {
    marginRight: '35px',
  },
  borderStyle: {
    border: '1px solid #000000',
    margin: theme.spacing(1),
    width: '233px',
    height: '50px',
    cursor: 'pointer',
  },
  compactPicker: {
    '& div': {
      zIndex: 1,
    },
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '22%',
    fontSize: '1em',
  },
  colorInput: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default useStyles;
