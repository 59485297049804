// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { InputBase, Popover, MenuItem, Select, TableCell, InputLabel, FormGroup, Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ACI } from 'Utils/autoCadIndexes';
import { CompactPicker } from 'react-color';
import { useAppDispatch } from 'Hooks';
import { EditLayerAttribute } from 'Features/map/mapEditorThunk';
import stylesDef from './styles';
import { getLineWeights } from 'Utils/constants';

interface ComponentProps {
  layer: any;
  siteId: string;
}

export const LinePropertiesFlyout: React.FC<ComponentProps> = ({ layer, siteId }) => {
  const theme = useTheme();
  const styles = stylesDef(theme);
  const dispatch = useAppDispatch();
  const [popOverOpen, setPopoverOpen] = useState(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openColor = Boolean(anchorEl);

  const lineWeights = getLineWeights();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event: any) => {
    setPopoverOpen(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(null);
  };

  const handleChangeWeight = async (layerId: any, e: any) => {
    const newLineWidth = e.target.value;
    dispatch(EditLayerAttribute(layerId, siteId, { lineWidth: newLineWidth }));
  };

  const handleChangeColor = async (
    layerId: number,
    color: { hex: string },
  ) => {
    const newColor = color.hex;
    dispatch(EditLayerAttribute(layerId, siteId, { color: newColor }));
  };

  // Contains line weights and corresponding heights
  const lineWeightDropdownContents = lineWeights.map((weight) => {
    if (weight < 10) {
      return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
        <hr
          style={{ ...styles.horizontalLine, height: `0.0${weight} mm` }}
        />
        <span>{weight}</span>
      </MenuItem>)
    } else if (weight > 9 && weight < 100) {
      return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
        <hr
          style={{ ...styles.horizontalLine, height: `0.${weight}mm` }}
        />
        <span>{weight}</span>
      </MenuItem>)
    } else {
      return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
        <hr
          style={{ ...styles.horizontalLine, height: '1mm', backgroundColor: '#000000' }}
        />
        <span>{weight}</span>
      </MenuItem>)
    }
  });

  const open = Boolean(popOverOpen);
  const id = open ? 'simple-popover' : undefined;

  // @ts-ignore
  return (
    <>
      <Box
        onClick={handlePopoverClick}
        sx={styles.layerColor}
        style={{ backgroundColor: layer.color }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={popOverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={styles.popover}
      >
        <Box sx={styles.formGroupContainer}>
          <FormGroup sx={styles.formGroup}>
            <InputLabel>Line Weight</InputLabel>
            <Select
              sx={styles.selectWeight}
              input={<InputBase style={styles.lineWeightInput} />}
              value={layer.lineWidth}
              variant="outlined"
              onChange={(e) => handleChangeWeight(layer.layerId, e)}
            >
              {lineWeightDropdownContents}
            </Select>
          </FormGroup>
          <FormGroup sx={styles.formGroup}>
            <InputLabel>Line Color</InputLabel>
            <Box
              sx={styles.lineColorContainer}
              onClick={(e) => handleClick(e)}
            >
              <Box sx={{...styles.lineColorSample, backgroundColor: layer.color}} />
              <Typography sx={styles.colorText}>{layer.color}</Typography>
              <Popover
                open={openColor}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <CompactPicker
                  color={layer.color}
                  colors={ACI.list}
                  onChange={(color: any, event: any) => {
                    handleChangeColor(layer.layerId, color)
                  }}
                  onChangeComplete={handleClose}
                />
              </Popover>
            </Box>
          </FormGroup>
        </Box>
      </Popover>
    </>
  );
};

export default LinePropertiesFlyout;
