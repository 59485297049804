// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// eslint-disable-next-line import/no-unresolved
import turfArea from '@turf/area';

const calculateAcres = (featureCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon>) => Math.round((turfArea(featureCollection) / 4046.856) * 10) / 10;

export const getTileStyleConditions = (classificationClasses: any) => classificationClasses.map((classification: any) => [
  `\${Classification}===${classification.classId}`, `color('${classification.color}')`,
]).concat([['true', "color('white')"]]);

export default calculateAcres;
