// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { datadogRum } from '@datadog/browser-rum';
import { postFile, postJson, patchJson } from 'Utils/http';
import GenerateGuid from 'Utils/guid';
import { API_URL } from 'Config';
import type { RootState } from 'Store';
import { GetVectorTilesThunk, GetRasterTilesThunk, GetLasBBoxThunk } from '../map/mapCommonThunk';
import { GetOrdersThunk, UpdateOrderCadFiles, UpdateOrderDeliverableFiles, UpdateOrderGeoDbFiles, UpdateOrderSurfaceFiles } from '../order/orderThunk';
import { SetTemplateId, AddTemplate } from '../account/cadTemplates/cadTemplateThunk';
import { getOrders, getOrderOwnerProject } from '../order/orderSelectors';
import {
  FileUploadStartAction,
  FileUploadUpdateAction,
  FileUploadCancelAction,
  FileUploadInitAction,
  SetFileUploadSizeErrorAction,
  SetImageryCaptureDateAction,
  SendExpressAIEmailStartAction,
  SendExpressAIEmailSuccessAction,
  SendExpressAIEmailFailureAction,
} from './fileUploadActions';
import { SetOutputLasAction, UpdateOrderAction, UpdateOrderLasFilesAction } from '../order/orderActions';
import { SetShowKmlToolbarAction, UpdateProjectAction, UpdateProjectLasFilesAction, UpdateProjectTifFilesAction } from '../project/projectActions';
import { ClearTileJsonAction } from '../map/mapCommonActions';
import { SetKmlErrorAction, SetLineStringUploadAction, SetMultipleGeometriesUploadAction } from '../kml/kmlActions';
import { ReadFromTextThunk, SetKmlThunk, ZoomToKml } from '../kml/kmlThunk';
import { UploadingDrawnKmlAction } from '../map/mapDrawActions';
import { DeleteLasFileThunk, DeleteTifFileThunk, GetProjectThunk } from '../project/projectThunk';
import { UpdateProjectListLasFileAction, UpdateProjectListTifFileAction } from '../projectList/projectListActions';

export const FileUploadStartThunk = (filesArray: File[], skipUpdateKml: boolean = false, cadTemplate: boolean = false) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { user, token } = getState().auth;
  const { orgId } = getState().admin;
  const { projectId } = getState().project;
  const orders = getOrders(getState());
  // Adding in a condition to check if activeOrders array is empty and assign it to orders.projectOrders[0]
  if (!orders.activeOrder) [orders.activeOrder] = orders.projectOrders;
  const order = orders.activeOrder;
  const promiseArray = filesArray.map((file) => {
    let _file = file;
    const { name } = _file;
    const id = GenerateGuid();
    let extension = name.substr(name.lastIndexOf('.') + 1);
    const { expressAIUpload, epsg, imageryCaptureDate } = getState().fileUpload;

    // Convert tiff to tif
    if (extension === 'tiff') {
      const content = _file.slice(
        0,
        _file.size,
        'image/tif',
      );
      const newName = name.substring(0, name.length - 1);
      _file = new File([content], newName, {
        type: 'image/tif',
      });
      extension = 'tif';
    }

    // Add Pendo Track Events
    if (!_ADMIN_ && !skipUpdateKml) {
      window?.pendo?.track('Upload Data', {
        projectId,
        type: extension,
        size: `${(_file.size / 1000000).toFixed(2)} MB`,
      });
    }

    if (extension === 'kml') {
      const content = _file.slice(
        0,
        _file.size,
        'application/vnd.google-earth.kml+xml',
      );
      _file = new File([content], name, {
        type: 'application/vnd.google-earth.kml+xml',
      });
      const f = getState().fileUpload.uploadFiles.find(
        (fi) => fi.extension === 'kml',
      );
      if (f) FileUploadCancelThunk(f.id)(dispatch, getState);
    }

    let url = '';
    let fieldName = '';
    let fileUploadKey = ''; // orgid/pid or orgid/pid/oid
    let uploadingProcessedLAS = false; // default to false, use this when the airworks admin uploads output las
    const u = _ADMIN_ ? orgId : user.ownerOrganization;
    switch (extension) {
    case 'kml': {
      if (_ADMIN_ && !order) {
        const { orderId } = getState().order;
        url = `${API_URL}/orders/uploadkml/${u}/${projectId}/${orderId}`;
      } else {
        url = `${API_URL}/orders/uploadkml/${u}/${projectId}/${order._id}`;
      }
      fieldName = 'kmlfile';
      break;
    }
    case 'tif': {
      fileUploadKey = `${u}/${projectId}`;
      url = `${API_URL}/orders/uploadtif/${fileUploadKey}`;
      fieldName = 'tiffile';
      break;
    }
    case 'xml': {
      const { orderId } = getState().order;
      url = `${API_URL}/orders/surfacefile/${orgId}/${projectId}/${orderId}`;
      fieldName = 'surfacefile';
      break;
    }
    case 'zip': {
      const { orderId } = getState().order;
      url = `${API_URL}/orders/geoDbfile/${orgId}/${projectId}/${orderId}`;
      fieldName = 'geodbfile';
      break;
    }
    case 'las': {
      if (_ADMIN_ && getState().order.outputLas) {
        uploadingProcessedLAS = true;
        const { orderId } = getState().order;
        const orderProject = getOrderOwnerProject(getState());
        fileUploadKey = `${u}/${projectId}/${orderId}`;
        url = `${API_URL}/orders/lasfile/${orgId}/${orderProject}/${orderId}`;
        dispatch(SetOutputLasAction(false));
      } else {
        fileUploadKey = `${u}/${projectId}`;
        url = `${API_URL}/orders/uploadlas/${u}/${projectId}`;
      }
      fieldName = 'lasfile';
      break;
    }
    case 'laz': {
      if (_ADMIN_ && getState().order.outputLas) {
        uploadingProcessedLAS = true;
        const { orderId } = getState().order;
        const orderProject = getOrderOwnerProject(getState());
        fileUploadKey = `${u}/${projectId}/${orderId}`;
        url = `${API_URL}/orders/lasfile/${orgId}/${orderProject}/${orderId}`;
        dispatch(SetOutputLasAction(false));
      } else {
        fileUploadKey = `${u}/${projectId}`;
        url = `${API_URL}/orders/uploadlas/${u}/${projectId}`;
      }
      fieldName = 'lasfile';
      break;
    }
    case 'dxf': {
      if (cadTemplate) {
        url = `${API_URL}/uploadTemplate/${u}`;
        fieldName = 'cadtemplate';
      } else {
        const { orderId } = getState().order;
        const orderProject = getOrderOwnerProject(getState());
        url = `${API_URL}/orders/cadFile/${u}/${orderProject}/${orderId}`;
        dispatch(SetOutputLasAction(false));
        fieldName = 'cadfile';
      }
      break;
    }
    case 'pdf': {
      url = `${API_URL}/orders/pointCloudQualityReport/${user.ownerOrganization}/${projectId}/${order._id}`;
      fieldName = 'pcreport';
      break;
    }
    default: {
      if (orgId) {
        const { orderId } = getState().order;
        const orderProject = getOrderOwnerProject(getState());
        url = `${API_URL}/orders/deliverableFiles/${orgId}/${orderProject}/${orderId}`;
        fieldName = 'deliverablefile';
      }
      break;
    }
    }

    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-shadow
      const onFileProgress = (id: string, progress: number, fileId: string, fileKey: string) => {
        dispatch(FileUploadUpdateAction({ id, progress, fileId, fileKey }));
      };

      const maxSizeInGB = 20;
      const fileSizeInGB = parseFloat((_file.size / 1000000000).toFixed(2));

      if (fileSizeInGB > maxSizeInGB) {
        dispatch(SetFileUploadSizeErrorAction(true));
        reject(new Error('File size over 20GB limit.'));
      }

      const onFileLoad = async (resultAsString: string) => {
        const result = JSON.parse(resultAsString);
        dispatch(SetOutputLasAction(false));
        switch (extension) {
        case 'kml': {
          if (result.error) {
            dispatch(SetKmlErrorAction(true));
            break;
          }
          if (result.geomType === 'LineString') {
            await GetOrdersThunk(projectId)(dispatch, getState);
            dispatch(SetLineStringUploadAction(true));
            break;
          }
          if (result.geomType === 'Multiple') {
            dispatch(SetMultipleGeometriesUploadAction(true));
          }
          if (!skipUpdateKml) {
            dispatch(SetShowKmlToolbarAction(false));
            ReadFromTextThunk(result.kmlString)(dispatch, getState);
          }
          dispatch(UploadingDrawnKmlAction(false));
          dispatch(UpdateOrderAction(result.order as IOrder));
          GetProjectThunk()(dispatch, getState);
          await GetOrdersThunk(projectId)(dispatch, getState);

          if (!skipUpdateKml) {
            ZoomToKml()(dispatch, getState);
          }
          break;
        }
        case 'tif': {
          const { tifFile, imageryRequest } = result as { tifFile: ITifFile, imageryRequest: IImageryRequest };
          dispatch(UpdateProjectTifFilesAction(tifFile));
          dispatch(UpdateProjectListTifFileAction(tifFile));
          // Get project details after moving to Project Details and CAD View page. This helps to show the loading screen on the map component
          GetProjectThunk()(dispatch, getState);
          GetRasterTilesThunk()(dispatch, getState);
          dispatch(SetImageryCaptureDateAction(null));
          break;
        }
        case 'xml': {
          const surfaceFileResponse = result as { surfaceFile: ISurfaceFile };
          UpdateOrderSurfaceFiles(surfaceFileResponse.surfaceFile)(dispatch);
          break;
        }
        case 'zip': {
          const geoDbFileResponse = result as { geoDbFile: IGeoDbFile };
          UpdateOrderGeoDbFiles(geoDbFileResponse.geoDbFile)(dispatch);
          break;
        }
        case 'las': {
          if (_ADMIN_ && result.lasFile && getState().order.outputLas) {
            const lasFileResponse = result as { lasFile: IOutputLasFile };
            dispatch(UpdateOrderLasFilesAction(lasFileResponse.lasFile));
          } else {
            const { lasFile, imageryRequest } = result as { lasFile: ILasFile, imageryRequest: IImageryRequest };
            dispatch(UpdateProjectLasFilesAction(lasFile));
            dispatch(UpdateProjectListLasFileAction(lasFile));
            GetProjectThunk()(dispatch, getState);
            GetLasBBoxThunk()(dispatch, getState);
            dispatch(SetImageryCaptureDateAction(null));
          }
          break;
        }
        case 'laz': {
          if (_ADMIN_ && result.order) {
            const orderResponse = result as { order: IOrder };
            dispatch(UpdateOrderAction(orderResponse.order));
          } else {
            const { lasFile, imageryRequest } = result as { lasFile: ILasFile, imageryRequest: IImageryRequest };
            dispatch(UpdateProjectLasFilesAction(lasFile));
            dispatch(UpdateProjectListLasFileAction(lasFile));
            GetProjectThunk()(dispatch, getState);
            GetLasBBoxThunk()(dispatch, getState);
            dispatch(SetImageryCaptureDateAction(null));
          }
          break;
        }
        case 'dxf': {
          if (cadTemplate) {
            const templateResponse = result as { template: ICadTemplate };
            AddTemplate(templateResponse.cadTemplate)(dispatch);
            SetTemplateId(templateResponse.cadTemplate._id)(dispatch);
          } else {
            const cadFileResponse = result as { cadFile: ICadFile };
            UpdateOrderCadFiles(cadFileResponse.cadFile)(dispatch);
            if (!_ADMIN_) {
              await GetProjectThunk()(dispatch, getState);
              await GetOrdersThunk(projectId)(dispatch, getState);
            }
            await GetVectorTilesThunk()(dispatch, getState);
          }
          break;
        }
        default: {
          const validExtensions = ['shp', 'dgn', 'dem', 'dtm', 'dwg'];
          if (validExtensions.includes(extension)) {
            const deliverableFileResponse = result as { deliverableFile: IDeliverableFile };
            UpdateOrderDeliverableFiles(deliverableFileResponse.deliverableFile)(dispatch);
          }
          break;
        }
        }
        dispatch(FileUploadUpdateAction({ id, progress: 100, success: true }));

        // datadog action - tracking user actions by passing custom attributes
        datadogRum.addAction('upload_end', {
          'file.name': _file?.name,
          'file.type': extension,
        });
        resolve();
      };

      const request = postFile(url, token, _file, _file.name, fieldName, expressAIUpload, epsg, imageryCaptureDate, onFileProgress, onFileLoad, id, fileUploadKey, fileSizeInGB, maxSizeInGB, uploadingProcessedLAS, skipUpdateKml);

      dispatch(FileUploadStartAction({ id, name, extension, request, progress: 0, success: false }));
      // datadog action - tracking user actions by passing custom attributes
      datadogRum.addAction('upload_start', {
        'file.name': _file?.name,
        'file.type': extension,
      });
    });
  });

  return Promise.all(promiseArray).catch((error) => {
    console.error(error);
  });
};

export const abortFileUpload = (data: {fileId: string, fileKey: string}) => async (
  getState: () => RootState,
) => {
  const url = `${API_URL}/orders/abortUpload`;
  const { token } = getState().auth;
  const result = await postJson<any>(url, data, token);
  return result;
};

export const FileUploadCancelThunk = (id: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const file = getState().fileUpload.uploadFiles.find((f) => f.id === id);
  const { extension, progress, fileId, fileKey } = file;

  if (extension === 'tif' && progress < 100) {
    const data = { fileId, fileKey };
    abortFileUpload(data)(getState);
    dispatch(FileUploadCancelAction(id));
  } else if (progress < 100) {
    file.request.abort();
    dispatch(FileUploadCancelAction(id));
  }
};

export const InitFileUploadThunk = () => (dispatch: Dispatch) =>
  dispatch(FileUploadInitAction());

export const DeleteUploadedFileThunk = (id: string, filename: string) => async (dispatch: Dispatch, getState: () => RootState) => {
  const file = filename.split(' ').join('');
  const fileType = filename.split('.')[1];
  const { tifFiles, lasFiles } = getState().project.project;
  let fileId;
  let tifFileToBeDeleted;
  let lasFileToBeDeleted;
  switch (fileType) {
  case 'tif':
    tifFileToBeDeleted = tifFiles.filter((tifFileRecord) => tifFileRecord.filename.includes(file));
    fileId = tifFileToBeDeleted[0]._id;
    await DeleteTifFileThunk(fileId)(dispatch, getState);
    break;
  case 'las':
  case 'laz':
    lasFileToBeDeleted = lasFiles.filter((lasFileRecord) => lasFileRecord.filename.includes(file));
    fileId = lasFileToBeDeleted[0]._id;
    await DeleteLasFileThunk(fileId)(dispatch, getState);
    break;
  case 'kml':
    await DeleteFileThunk('kml')(dispatch, getState);
    break;
  default:
    break;
  }
  dispatch(FileUploadCancelAction(id));
};

export const DeleteFileThunk = (fileType: FileType) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const orders = getOrders(getState());
  const { projectId } = getState().project;
  const { activeOrder } = orders;

  const { token } = getState().auth;
  const url = `${API_URL}/orders/${activeOrder._id}`;
  const data: {
    tif?: string | null;
    las?: string | null;
    boundaryFile?: string | null;
  } = {};

  switch (fileType) {
  case 'kml':
    data.boundaryFile = null;
    break;
  case 'las':
    data.las = null;
    break;
  default:
    data.tif = null;
  }

  const result = await patchJson<any>(url, data, token);
  if (!result.success) return;

  if (fileType === 'kml') {
    dispatch(UpdateOrderAction(result.data.order as IOrder));
    SetKmlThunk(null)(dispatch, getState);
    GetOrdersThunk(projectId)(dispatch, getState);
  } else {
    dispatch(UpdateProjectAction(result.data as IProject));
    dispatch(ClearTileJsonAction('raster'));
  }
};

export const SendExpressAIEmailThunk = (orderId: string, layers: string[]) => async (dispatch: Dispatch, getState: () => IState) => {
  const url = `${API_URL}/orders/cadFile/email`;
  const { token } = getState().auth;
  const data = {
    orderId,
    layers,
  };
  dispatch(SendExpressAIEmailStartAction());
  const result = await postJson<any>(url, data, token);
  if (result.success) {
    dispatch(SendExpressAIEmailSuccessAction(result.data));
    const cadFileResponse = result.data as { updatedCadFile: ICadFile };
    UpdateOrderCadFiles(cadFileResponse.updatedCadFile)(dispatch);
  } else {
    dispatch(SendExpressAIEmailFailureAction(result.message));
  }

  return result;
};
