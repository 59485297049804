// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const useStyles = (theme: any) => ({
  layersLabel: {
    color: theme.palette.darkGrey,
  },
  listWrapper: {
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  divider: {
    margin: '10px',
  },
  addIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '24px',
  },
  newLayerButton: {
    paddingLeft: '16px',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  deselectButton: {
    color: 'grey',
  },
  layerDivider: {
    backgroundColor: '#eee',
    marginLeft: '20px',
  },
  checkboxWrapper: {
    top: '50%',
    right: '0px',
    position: 'relative',
    transform: 'unset',
  },
  listItem: {
    paddingBottom: '0px',
    paddingTop: '0px',
    // '&:hover': {
    // backgroundColor: 'transparent !important',
    // },
    '& .Mui-selected': {
      backgroundColor: '#eee !important',
    },
  },
  listItemButton: {
    textTransform: 'none',
    padding: '0px',
    width: '350px',
    fontWeight: 300,
    justifyContent: 'left',
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  projectLayersText: {
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listItemText: {
    fontWeight: 300,
    fontSize: '14px',
    width: '350px',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '550px',
    },
  },
  dialogText: {
    fontWeight: 400,
  },
  dialogButton: {
    color: theme.palette.primary.main,
  },
  inputField: {
    width: '210px',
    padding: '4px',
    background: 'white',
    height: '14px',
  },
  layerColor: {
    height: '20px',
    width: '10px',
    border: '1px solid #000000',
  },
  layerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expressAIInfo: {
    background: '#eee',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  aiIcon: {
    marginTop: theme.spacing(1),
  },
  aiTextBox: {
    padding: theme.spacing(0.5),
  },
  aiText: {
    fontSize: '14px',
  },
  aiTextDate: {
    fontSize: '14px',
    color: '#01A1DD',
  },
});

export default useStyles;
