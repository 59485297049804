// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, FormGroup, InputLabel, Popover, TextField, Typography, MenuItem, Select, InputBase } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import stylesDef from './styles';
import { CompactPicker } from 'react-color';
import { useAppDispatch } from 'Hooks';
import { AddNewLayer } from 'Features/map/mapEditorThunk';
import ACI from 'Utils/autoCadIndexes';
import { getLineWeights } from 'Utils/constants';

interface NewLayerDialogProps {
    showNewLayerDialog: boolean;
    setNewLayerDialog: (value: boolean) => void;
    siteId: string;
}

export const NewLayerDialog = ({showNewLayerDialog, setNewLayerDialog, siteId}: NewLayerDialogProps) => {
    const theme = useTheme();
    const styles = stylesDef(theme);
    const dispatch = useAppDispatch();

    const [color, setColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(0);
    const [layerName, setLayerName] = useState("");

    const lineWeight: number[] = getLineWeights();
    
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeWeight = async (e: any) => {
        setLineWidth(e.target.value);
    };

    // Contains line weights and corresponding heights
    const lineWeightDropdownContents = lineWeight.map((weight) => {

        if (weight < 10) {
            return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
                <hr
                    style={{ ...styles.horizontalLine, height: `0.0${weight} mm` }}
                />
                <span>{weight}</span>
            </MenuItem>)
        } else if (weight > 9 && weight < 100) {
            return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
                <hr
                    style={{ ...styles.horizontalLine, height: `0.${weight}mm` }}
                />
                <span>{weight}</span>
            </MenuItem>)
        } else {
            return (<MenuItem key={weight} value={weight} sx={styles.menuItem}>
                <hr
                    style={{ ...styles.horizontalLine, height: '1mm', backgroundColor: '#000000' }}
                />
                <span>{weight}</span>
            </MenuItem>)
        }
    });

    return (
        <Dialog sx={styles.newLayerDialog} open={showNewLayerDialog} onClose={() => setNewLayerDialog(false)}>
            <DialogContent sx={styles.newLayerDialogContent}>
                <Box sx={styles.newLayerDialogTitle}>
                    <InfoIcon color='info' sx={styles.infoIcon} />
                    <Typography variant='h3'>
                        See something that's not on this file?
                    </Typography>
                </Box>
                <Typography sx={styles.dialogText}>
                    Add a new layer on the fly:
                </Typography>
                <TextField 
                    sx={styles.layerNameInput} 
                    fullWidth 
                    label="Layer Name" 
                    id="layer-name" 
                    required
                    value={layerName}
                    onChange={(e) => setLayerName(e.target.value)}
                />
                <Box sx={styles.formGroupContainer}>
                    <FormGroup sx={styles.formGroup}>
                        <InputLabel>Line Weight</InputLabel>
                        <Select
                            sx={styles.selectWeight}
                            input={<InputBase style={styles.lineWeightInput} />}
                            value={lineWidth}
                            variant="outlined"
                            onChange={(e) => handleChangeWeight(e)}
                        >
                            {lineWeightDropdownContents}
                        </Select>
                    </FormGroup>
                    <FormGroup sx={styles.formGroup}>
                        <InputLabel>Line Color</InputLabel>
                        <Box 
                            sx={styles.lineColorInput} 
                            onClick={(e)=> handleClick(e)}
                        >
                            <Box sx={{...styles.lineColorSample, backgroundColor:color}} />
                            <Typography sx={styles.lineColorText}>{color}</Typography>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                            >
                                <CompactPicker
                                    color={color}
                                    colors={ACI.list}
                                    onChange={(color: any, event: any) => {
                                        setColor(color.hex)
                                    }} 
                                    onChangeComplete={handleClose}
                                />
                            </Popover>
                        </Box>
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={styles.closeButton} color='secondary' onClick={() => setNewLayerDialog(false)}>Close</Button>
                <Button sx={styles.dialogButton} disabled={layerName.trim() == ''} onClick={() => {
                    const layer = {color, lineWidth:lineWidth, lineType:'Continuous', name:layerName}
                    dispatch(AddNewLayer(siteId, layer))
                    setNewLayerDialog(false)
                }}>Continue</Button>
            </DialogActions>
        </Dialog>
    );
};